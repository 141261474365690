import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./styled";

const Entry: React.FC = (): React.ReactElement => {
  const [t] = useTranslation();

  const click = () => {
    window.scrollTo(0,document.body.scrollHeight);
  }
  return (
    <S.EntryWrapper>
      <S.Title>{t("Entry.Title")}</S.Title>
      <S.Subtitle>{t("Entry.Subtitle")}</S.Subtitle>
      <S.JoinButton onClick ={click}>{t("Entry.TextButton")}</S.JoinButton>
    </S.EntryWrapper>
  );
};

export { Entry };
