import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 50px;
  max-width: 800px;

  @media (max-width: 650px) {
    padding: 45px 30px;
  }
`;

export const MainTitle = styled.div`
  font-size: 34px;
  font-weight: 700;
  text-align: center;
`;

export const MainSubtitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`;

export const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
`;

export const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
`;

export const Separator = styled.div`
  height: 0.5px;
  background-color: rgba(255, 255, 255, 0.432);
  width: 100%;
`;

export const Icon = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1px;
`;

export const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
`;
