import React from "react";
import { styled } from "styled-components";

import {
  Header,
  Entry,
  RedefiningLoyalty,
  MobileAdvantages,
  NFTCollection,
  Footer,
  NFTMarketplace,
  AnalyticDashboard,
  ContactForm,
} from "./components";

import backgroundImage from "./assets/images/background.png";

const Wrapper = styled.div`
  max-width: 1150px;
  background-image: url(${backgroundImage});
  background-size: cover;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
`;

function App() {
  return (
    <div className="App">
      <Wrapper>
        <Header />
        <Entry />
        <RedefiningLoyalty />
        <MobileAdvantages />
        <NFTCollection />
        <NFTMarketplace />
        <AnalyticDashboard />
        <ContactForm />
        <Footer />
      </Wrapper>
    </div>
  );
}

export default App;
