import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const ArrowIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="15"
      viewBox="0 0 28 15"
      fill="none"
      {...rest}
    >
      <path
        d="M26.5992 1.90735e-06L1.39844 1.90735e-06C1.1433 0.000574112 0.8932 0.0509272 0.675074 0.145639C0.456947 0.240352 0.279051 0.375836 0.160532 0.537512C0.0420132 0.699188 -0.01264 0.880933 0.00245667 1.06318C0.0175533 1.24543 0.101828 1.42128 0.246208 1.57181L12.8466 14.595C13.3688 15.135 14.626 15.135 15.1496 14.595L27.75 1.57181C27.8958 1.42159 27.9814 1.24565 27.9973 1.0631C28.0132 0.88055 27.9589 0.698369 27.8403 0.536351C27.7216 0.374332 27.5432 0.238673 27.3244 0.144114C27.1056 0.0495558 26.8548 -0.000286102 26.5992 1.90735e-06Z"
        fill="#F8F8F8"
        fill-opacity="0.5"
      />
    </svg>
  );
};

const MemoArrowIcon = memo(ArrowIcon);
export default MemoArrowIcon;
