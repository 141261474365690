import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { CloseIcon, OpenIcon } from "../../assets/icons";
import analyticDashboardImage from "../../assets/images/analytic-dashboard.png";

import * as S from "./styled";

interface Paragraph {
  title: string;
  text: string;
}

const AnalyticDashboard: React.FC = (): React.ReactElement => {
  const [t] = useTranslation();

  const paragraphsData: Paragraph[] = [
    {
      title: t('AnalyticDashboard.Title1'),
      text: t('AnalyticDashboard.Text1'),
    },
    {
      title: t('AnalyticDashboard.Title2'),
      text: t('AnalyticDashboard.Text2'),
    },
    {
      title: t('AnalyticDashboard.Title3'),
      text: t('AnalyticDashboard.Text3'),
    },
    {
      title: t('AnalyticDashboard.Title4'),
      text: t('AnalyticDashboard.Text4'),
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean[]>(
    Array(paragraphsData.length).fill(true)
  );

  const toggleParagraph = (index: number) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <S.Wrapper>
      <S.Description>
        <S.MainTitle>{t("AnalyticDashboard.MainTitle")}</S.MainTitle>
        <S.Paragraphs>
          {paragraphsData.map((paragraph, index) => (
            <S.Paragraph key={index}>
              <S.Separator />
              <S.Icon onClick={() => toggleParagraph(index)}>
                {isOpen[index] && <OpenIcon width={"12px"} height={"12px"} />}
                {!isOpen[index] && <CloseIcon width={"10px"} height={"10px"} />}
              </S.Icon>
              <S.Title>{paragraph.title}</S.Title>
              <motion.div
                key={index}
                initial={{ opacity: 0, height: 0 }}
                animate={{
                  opacity: isOpen[index] ? 1 : 0,
                  height: isOpen[index] ? "auto" : 0,
                }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <S.Text
                  style={{
                    maxHeight: isOpen[index] ? "none" : 0,
                    overflow: "hidden",
                  }}
                >
                  {paragraph.text}
                </S.Text>
              </motion.div>
            </S.Paragraph>
          ))}
        </S.Paragraphs>
      </S.Description>
      <S.PictureWrapper>
        <S.ElipseShadow />
        <S.Image src={analyticDashboardImage} alt="analytic-dashboard" />
      </S.PictureWrapper>
    </S.Wrapper>
  );
};

export { AnalyticDashboard };
