import { styled } from "styled-components";

export const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 40px;
  row-gap: 17px;
  padding: 0 30px;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 54px;
  font-weight: 700;
  max-width: 1000px;
`;

export const Subtitle = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 2px white;
`;

export const JoinButton = styled.div`
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 15px;
  padding: 6px 15px;
  margin-top: 30px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;
