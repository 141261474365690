import { styled } from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 0;
  column-gap: 10px;
`;

export const MQInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2px;
`;

export const MQLogo = styled.div`
  img {
    width: 35px;
    height: 35px;

    @media (max-width: 645px) {
      width: 30px;
      height: 30px;
    }

    @media (max-width: 485px) {
      width: 25px;
      height: 25px;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MQTitle = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 645px) {
    font-size: 16px;
  }

  @media (max-width: 420px) {
    font-size: 13px;
  }
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  @media (max-width: 485px) {
    column-gap: 4px;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  padding: 3px 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (max-width: 645px) {
    padding: 3px 10px;
    font-size: 13px;
  }

  @media (max-width: 485px) {
    padding: 3px 7px;
    font-size: 11px;
  }
`;

export const Select = styled.div`
  position: relative;
`;

export const ItemSelect = styled.div`
  cursor: pointer;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  padding: 3px 10px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;

  @media (max-width: 645px) {
    padding: 3px 10px;
    font-size: 13px;
  }

  @media (max-width: 485px) {
    padding: 3px 7px;
    font-size: 11px;
  }
`;

export const SelectBody = styled.div`
  position: absolute;
  background-color: white;
  top: 30px;
  left: 0;
  right: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const LanguageOption = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ selected }) =>
    selected ? "rgb(107, 49, 254)" : "rgb(107, 49, 254)"};
  background-color: ${({ selected }) =>
    selected ? "rgba(0, 0, 0, 0.1)" : "transparent"};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ArrowWrapper = styled.div<{ rotate: string }>`
  transition: transform 0.3s ease;
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${({ rotate }) => rotate});
`;
