import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./styled";

const RedefiningLoyalty: React.FC = (): React.ReactElement => {
  const [t] = useTranslation();

  return (
    <S.Wrapper>
      <S.Title>{t("RedefiningLoyalty.Title")}</S.Title>
      <S.BlocksWrapper>
        <S.Column side="left">
          <S.Block>
            <S.BlockTitle>{t("RedefiningLoyalty.BlockTitle1")}</S.BlockTitle>
            <S.BlockSubtitle>
              {t("RedefiningLoyalty.BlockSubtitle1")}
            </S.BlockSubtitle>
          </S.Block>
          <S.Block withBorder>
            <S.BlockTitle>{t("RedefiningLoyalty.BlockTitle2")}</S.BlockTitle>
            <S.BlockSubtitle>
              {t("RedefiningLoyalty.BlockSubtitle2")}
            </S.BlockSubtitle>
          </S.Block>
          <S.Block>
            <S.BlockTitle>{t("RedefiningLoyalty.BlockTitle3")}</S.BlockTitle>
            <S.BlockSubtitle>
              {t("RedefiningLoyalty.BlockSubtitle3")}
            </S.BlockSubtitle>
          </S.Block>
        </S.Column>
        <S.Column side="right">
          <S.Block withBorder>
            <S.BlockTitle>{t("RedefiningLoyalty.BlockTitle4")}</S.BlockTitle>
            <S.BlockSubtitle>
              {t("RedefiningLoyalty.BlockSubtitle4")}
            </S.BlockSubtitle>
          </S.Block>
          <S.Block>
            <S.BlockTitle>{t("RedefiningLoyalty.BlockTitle5")}</S.BlockTitle>
            <S.BlockSubtitle>
              {t("RedefiningLoyalty.BlockSubtitle5")}
            </S.BlockSubtitle>
          </S.Block>
          <S.Block withBorder>
            <S.BlockTitle>{t("RedefiningLoyalty.BlockTitle6")}</S.BlockTitle>
            <S.BlockSubtitle>
              {t("RedefiningLoyalty.BlockSubtitle6")}
            </S.BlockSubtitle>
          </S.Block>
        </S.Column>
      </S.BlocksWrapper>
    </S.Wrapper>
  );
};

export { RedefiningLoyalty };
