import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const CloseIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...rest}
    >
      <path
        d="M0.00012207 25.9999C0.00012207 18.8202 5.82042 12.9999 13.0001 12.9999C13.0001 20.1796 7.17982 25.9999 0.00012207 25.9999Z"
        fill="white"
      />
      <path
        d="M26.0001 25.9999C18.8204 25.9999 13.0001 20.1796 13.0001 12.9999C20.1798 12.9999 26.0001 18.8202 26.0001 25.9999Z"
        fill="white"
      />
      <path
        d="M13.0001 12.9999C5.82042 12.9999 0.00012207 7.17958 0.00012207 -0.000119746C7.17982 -0.000119746 13.0001 5.82018 13.0001 12.9999Z"
        fill="white"
      />
      <path
        d="M26.0001 -0.000119746C26.0001 7.17958 20.1798 12.9999 13.0001 12.9999C13.0001 5.82018 18.8204 -0.000119746 26.0001 -0.000119746Z"
        fill="white"
      />
    </svg>
  );
};

const MemoCloseIcon = memo(CloseIcon);
export default MemoCloseIcon;
