import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { CloseIcon, OpenIcon } from "../../assets/icons";

import * as S from "./styled";

interface Paragraph {
  title: string;
  text: string;
}

const paragraphsData: Paragraph[] = [
  {
    title: "Centralized Reward Hub",
    text: "Experience the convenience of a single marketplace hosting NFTs from a multitude of businesses. Browse, manage, and redeem rewards seamlessly in one place.",
  },
  {
    title: "Curated Collections",
    text: "Explore curated collections of NFTs that span diverse industries and brands. From fashion to tech, immerse yourself in a digital treasure trove of loyalty rewards.",
  },
  {
    title: "Trade and Exchange",
    text: "Engage in the excitement of trading or purchasing NFTs. The marketplace fosters a vibrant community, enabling you to acquire unique tokens and expand your digital portfolio.",
  },
  {
    title: "Exclusive Discoveries",
    text: "Unearth limited-edition NFTs available exclusively through the marketplace. Elevate your digital assets with sought-after collectibles that hold genuine value.",
  },
];

const NFTMarketplace: React.FC = (): React.ReactElement => {
  const [t] = useTranslation();


  const paragraphsData: Paragraph[] = [
    {
      title: t('NFTMarketplace.Title1'),
      text: t('NFTMarketplace.Text1'),
    },
    {
      title: t('NFTMarketplace.Title2'),
      text: t('NFTMarketplace.Text2'),
    },
    {
      title: t('NFTMarketplace.Title3'),
      text: t('NFTMarketplace.Text3'),
    },
    {
      title: t('NFTMarketplace.Title4'),
      text: t('NFTMarketplace.Text4'),
    },
  ];
  const [isOpen, setIsOpen] = useState<boolean[]>(
    Array(paragraphsData.length).fill(true)
  );

  const toggleParagraph = (index: number) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <S.Wrapper>
      <S.MainTitle>{t("NFTMarketplace.MainTitle")}</S.MainTitle>
      <S.MainSubtitle>
        {t("NFTMarketplace.MainSubtitle")}
      </S.MainSubtitle>
      <S.BlocksWrapper>
        {paragraphsData.map((paragraph, index) => (
          <S.Block key={index}>
            <S.Title>
              <S.Icon onClick={() => toggleParagraph(index)}>
                {isOpen[index] && <OpenIcon width={"12px"} height={"12px"} />}
                {!isOpen[index] && <CloseIcon width={"10px"} height={"10px"} />}
              </S.Icon>
              {paragraph.title}
            </S.Title>
            <S.Separator />
            <motion.div
              key={index}
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: isOpen[index] ? 1 : 0,
                height: isOpen[index] ? "auto" : 0,
              }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <S.Subtitle
                style={{
                  maxHeight: isOpen[index] ? "none" : 0,
                  overflow: "hidden",
                }}
              >
                {paragraph.text}
              </S.Subtitle>
            </motion.div>
          </S.Block>
        ))}
      </S.BlocksWrapper>
    </S.Wrapper>
  );
};

export { NFTMarketplace };
