import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const TwitterIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      {...rest}
    >
      <path
        d="M41.8433 10.9997C40.4317 11.6413 38.91 12.063 37.3333 12.2647C38.9467 11.293 40.1933 9.75301 40.78 7.90134C39.2583 8.81801 37.5717 9.45967 35.7933 9.82634C34.345 8.24967 32.31 7.33301 30 7.33301C25.6917 7.33301 22.1717 10.853 22.1717 15.198C22.1717 15.8213 22.245 16.4263 22.3733 16.9947C15.8467 16.6647 10.035 13.5297 6.16667 8.78134C5.48834 9.93634 5.10334 11.293 5.10334 12.723C5.10334 15.4547 6.47834 17.8747 8.60501 19.2497C7.30334 19.2497 6.09334 18.883 5.03001 18.333V18.388C5.03001 22.2013 7.74334 25.3913 11.3367 26.1063C10.183 26.4221 8.97185 26.466 7.79834 26.2347C8.29628 27.7976 9.27149 29.1651 10.5869 30.145C11.9022 31.125 13.4917 31.6681 15.1317 31.698C12.3517 33.8988 8.90566 35.0884 5.36001 35.0713C4.73667 35.0713 4.11334 35.0347 3.49001 34.9613C6.97334 37.198 11.1167 38.4997 15.5533 38.4997C30 38.4997 37.9383 26.5097 37.9383 16.1147C37.9383 15.7663 37.9383 15.4363 37.92 15.088C39.46 13.988 40.78 12.5947 41.8433 10.9997Z"
        fill="#F8F8F8"
        fill-opacity="0.5"
      />
    </svg>
  );
};

const MemoTwitterIcon = memo(TwitterIcon);
export default MemoTwitterIcon;
