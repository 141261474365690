import React from "react";

import nftCollectionImage from "../../assets/images/nft-collection.png";

import * as S from "./styled";

const NFTCollection: React.FC = (): React.ReactElement => {
  return (
    <>
      <S.Wrapper>
        <S.Image src={nftCollectionImage} alt="nft-collection" />
      </S.Wrapper>
      <S.Separator />
    </>
  );
};

export { NFTCollection };
