import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const MediumIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      {...rest}
    >
      <path
        d="M7.66851 5.5H37.0018C37.4881 5.5 37.9544 5.69315 38.2982 6.03697C38.642 6.38079 38.8352 6.8471 38.8352 7.33333V36.6667C38.8352 37.1529 38.642 37.6192 38.2982 37.963C37.9544 38.3068 37.4881 38.5 37.0018 38.5H7.66851C7.18228 38.5 6.71596 38.3068 6.37215 37.963C6.02833 37.6192 5.83517 37.1529 5.83517 36.6667V7.33333C5.83517 6.8471 6.02833 6.38079 6.37215 6.03697C6.71596 5.69315 7.18228 5.5 7.66851 5.5ZM32.0518 29.2233C31.8685 29.1317 31.7768 28.8567 31.7768 28.6715V14.6777C31.7768 14.4943 31.8685 14.2193 32.0518 14.0342L33.8027 12.0083V11.9167H27.5418L22.8485 23.793L17.5062 11.9167H11.062V12.0083L12.7193 14.311C13.0878 14.6777 13.1795 15.2313 13.1795 15.6915V25.817C13.2712 26.3707 13.1795 27.0142 12.9045 27.566L10.4167 30.9723V31.064H17.046V30.9723L14.56 27.6595C14.285 27.1058 14.1915 26.554 14.285 25.9105V16.7017C14.3767 16.885 14.4683 16.885 14.56 17.2535L20.819 31.2473H20.9107L26.99 16.06C26.8983 16.61 26.8983 17.2553 26.8983 17.7173V28.5798C26.8983 28.8548 26.8067 29.0382 26.6233 29.2233L24.7808 30.9723V31.064H33.8027V30.9723L32.0518 29.2233Z"
        fill="#F8F8F8"
        fill-opacity="0.5"
      />
    </svg>
  );
};

const MemoMediumIcon = memo(MediumIcon);
export default MemoMediumIcon;
