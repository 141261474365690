import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const LinkedInIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...rest}
    >
      <path
        d="M34.8333 5.5C35.8058 5.5 36.7384 5.88631 37.4261 6.57394C38.1137 7.26157 38.5 8.19421 38.5 9.16667V34.8333C38.5 35.8058 38.1137 36.7384 37.4261 37.4261C36.7384 38.1137 35.8058 38.5 34.8333 38.5H9.16667C8.19421 38.5 7.26157 38.1137 6.57394 37.4261C5.88631 36.7384 5.5 35.8058 5.5 34.8333V9.16667C5.5 8.19421 5.88631 7.26157 6.57394 6.57394C7.26157 5.88631 8.19421 5.5 9.16667 5.5H34.8333ZM33.9167 33.9167V24.2C33.9167 22.6149 33.287 21.0947 32.1661 19.9739C31.0453 18.853 29.5251 18.2233 27.94 18.2233C26.3817 18.2233 24.5667 19.1767 23.6867 20.6067V18.5717H18.5717V33.9167H23.6867V24.8783C23.6867 23.4667 24.8233 22.3117 26.235 22.3117C26.9157 22.3117 27.5686 22.5821 28.0499 23.0634C28.5313 23.5448 28.8017 24.1976 28.8017 24.8783V33.9167H33.9167ZM12.6133 15.6933C13.4302 15.6933 14.2136 15.3688 14.7912 14.7912C15.3688 14.2136 15.6933 13.4302 15.6933 12.6133C15.6933 10.9083 14.3183 9.515 12.6133 9.515C11.7916 9.515 11.0035 9.84143 10.4225 10.4225C9.84143 11.0035 9.515 11.7916 9.515 12.6133C9.515 14.3183 10.9083 15.6933 12.6133 15.6933ZM15.1617 33.9167V18.5717H10.0833V33.9167H15.1617Z"
        fill="#F8F8F8"
        fill-opacity="0.5"
      />
    </svg>
  );
};

const MemoLinkedInIcon = memo(LinkedInIcon);
export default MemoLinkedInIcon;
