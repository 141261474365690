import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  max-width: 550px;
  border-radius: 30px;
  border: 3px solid #fff;
  margin: 50px 0;

  @media (max-width: 740px) {
    max-width: 450px;
  }

  @media (max-width: 520px) {
    max-width: 400px;
  }

  @media (max-width: 440px) {
    max-width: 350px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 36px;
  font-weight: 700;

  @media (max-width: 440px) {
    font-size: 34px;
  }
`;

export const Subtitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  max-width: 210px;
  line-height: 18px;
  margin: 10px 0;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  width: 100%;
  max-width: 300px;

  @media (max-width: 520px) {
    max-width: 250px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 3px;
  width: 100%;
  position: relative;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  text-align: start;
`;

export const Input = styled.input`
  all: unset;
  padding: 10px;
  border-radius: 10px;
  background: rgba(122, 69, 254, 0.65);
  color: white;
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  width: calc(100% - 20px);

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const SendButton = styled.button`
  width: calc(100% + 60px);
  border-radius: 15px;
  border: 3px solid #fff;
  background: rgba(122, 69, 254, 0.86);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 30px;
  cursor: pointer;
`;

export const Select = styled.select`
  all: unset;
  padding: 10px;
  border-radius: 10px;
  background: rgba(122, 69, 254, 0.65);
  color: rgb(255, 255, 255);
  font-size: 11px;
  font-weight: 400;
  width: calc(100% - 20px);

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const ErrorMessage = styled.div`
  width: 100%;
  text-align: end;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 400;
`;
