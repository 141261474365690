import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  width: 100%;
`;

export const Title = styled.div`
  background-color: #6b31fe;
  padding: 30px 20px;
  text-align: center;
  font-size: 34px;
  font-weight: 700;
`;

export const BlocksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 0 30px;
  justify-content: center;
  align-items: center;

  @media (max-width: 990px) {
    gap: 15px;
  }
`;

export const Block = styled.div<{ withBorder?: boolean }>`
  border-radius: 30px;
  background: rgba(122, 69, 254, 0.58);
  max-width: 400px;
  padding: 45px 10px;
  min-height: 130px;
  justify-self: center;
  align-self: center;

  ${(props) =>
    props.withBorder &&
    css`
      border: 2px solid #fff;
      background-color: rgba(255, 0, 0, 0);
    `}

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BlockTitle = styled.div`
  font-size: 17px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-weight: 700;
`;

export const BlockSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  flex-grow: 1;
`;

export const Column = styled.div<{ side: String }>`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  @media (max-width: 990px) {
    row-gap: 15px;
  }

  ${(props) =>
    props.side === "left" &&
    css`
      align-items: flex-end;
      margin-left: auto;
    `}

  ${(props) =>
    props.side === "right" &&
    css`
      align-items: flex-start;
      margin-right: auto;
    `}
`;
