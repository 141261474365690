import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0 50px;
  max-width: 800px;

  @media (max-width: 650px) {
    padding: 0 30px;
  }
`;

export const Description = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

export const MainTitle = styled.div`
  font-size: 34px;
  font-weight: 700;
  line-height: 45px;
  width: 100%;

  @media (max-width: 560px) {
    max-width: 200px;
    font-size: 23px;
    line-height: 35px;
  }
`;

export const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
`;

export const Paragraph = styled.div`
  position: relative;
  font-size: 17px;

  color: rgba(255, 255, 255, 0.744);
  font-weight: 400;
  line-height: 25px;
`;

export const Title = styled.div`
  font-weight: 700;
  color: white;

  @media (max-width: 565px) {
    max-width: 170px;
  }

  @media (max-width: 505px) {
    max-width: 130px;
  }
`;

export const PictureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
`;

export const Image = styled.img`
  max-width: 130%;
  height: auto;
`;

export const ElipseShadow = styled.div`
  border-radius: 1064px;
  background: radial-gradient(
    49.95% 49.95% at 50.05% 50.05%,
    rgba(252, 252, 252, 0.55) 0%,
    rgba(248, 248, 248, 0) 100%
  );
  position: absolute;
  z-index: -1;

  width: 100%;
  height: 100%;

  @media (max-width: 800px) {
    height: 80%;
  }

  @media (max-width: 620px) {
    height: 70%;
  }

  @media (max-width: 572px) {
    height: 60%;
  }

  @media (max-width: 500px) {
    height: 35%;
  }
`;

export const Separator = styled.div`
  height: 0.5px;
  background-color: rgba(255, 255, 255, 0.432);
  width: 100%;
`;

export const Icon = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1px;
`;

export const Text = styled.div`
  overflow: hidden;
  max-height: none;
  transition: max-height 0.3s ease;
`;
