import React from "react";
import { useTranslation } from "react-i18next";

import {
  LinkedInIcon,
  MediumIcon,
  TwitterIcon,
  StarIcon,
} from "../../assets/icons";
import mqLogo from "../../assets/images/mqicon-footer.png";

import * as S from "./styled";


const Footer: React.FC = (): React.ReactElement => {
  const [t] = useTranslation();

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };



  return (
    <S.FooterWrapper>
      <S.Icon>
        <img src={mqLogo} alt="mq-logo" />
      </S.Icon>
      <S.Title>
        {t("Footer.Label1")}
        <StarIcon
          fill="rgba(255, 255, 255, 0.5)"
          width={"15px"}
          height={"15px"}
        />
        {t("Footer.Label2")}
        <StarIcon
          fill="rgba(255, 255, 255, 0.5)"
          width={"15px"}
          height={"15px"}
        />
        {t("Footer.Label3")}
      </S.Title>
      <S.SocialNetworks>
        <LinkedInIcon width={"25px"} height={"25px"} />
        <MediumIcon onClick={() => openInNewTab("https://medium.com/@mq.loyalty")} width={"25px"} height={"25px"} />
        <TwitterIcon onClick={() => openInNewTab("https://twitter.com/mqloyalty")} width={"25px"} height={"25px"} />
      </S.SocialNetworks>
    </S.FooterWrapper>
  );
};

export { Footer };
