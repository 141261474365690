import { styled, css } from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  padding: 0 30px;
  padding-top: 50px;
  padding-bottom: 20px;

  @media (max-width: 750px) {
    gap: 30px;
  }
`;

export const Description = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

export const MainTitle = styled.div`
  font-size: 34px;
  font-weight: 700;
  line-height: 45px;

  @media (max-width: 560px) {
    max-width: 200px;
    font-size: 23px;
    line-height: 35px;
  }
`;

export const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
`;

export const Paragraph = styled.div`
  position: relative;
  font-size: 17px;

  color: rgba(255, 255, 255, 0.744);
  font-weight: 400;
  line-height: 25px;
`;

export const Title = styled.div`
  font-weight: 700;
  color: white;

  @media (max-width: 580px) {
    max-width: 160px;
  }

  @media (max-width: 550px) {
    max-width: 120px;
  }
`;

export const Text = styled.div`
  overflow: hidden;
  max-height: none;
`;

export const Pictures = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Image = styled.div<{ number: Number }>`
  position: absolute;
  top: 50%;
  left: 50%;

  img {
    max-width: 170px;
    height: auto;

    @media (max-width: 680px) {
      max-width: 80px;
    }
  }

  ${(props) =>
    props.number === 1 &&
    css`
      transform: translate(-100%, -60%);
      z-index: 3;
    `}

  ${(props) =>
    props.number === 2 &&
    css`
      transform: translate(-50%, -50%);
      z-index: 2;
    `}

    ${(props) =>
    props.number === 3 &&
    css`
      transform: translate(0%, -35%);
      z-index: 1;
    `}
`;

export const ElipseShadow = styled.div`
  border-radius: 1064px;
  background: radial-gradient(
    49.95% 49.95% at 50.05% 50.05%,
    rgba(252, 252, 252, 0.55) 0%,
    rgba(248, 248, 248, 0) 100%
  );
  z-index: -1;

  width: 330px;
  height: 500px;

  @media (max-width: 680px) {
    width: 100px;
    height: 250px;
  }
`;

export const Separator = styled.div`
  height: 0.5px;
  background-color: rgba(255, 255, 255, 0.432);
  width: 100%;
`;

export const Icon = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1px;
`;
