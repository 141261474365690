import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const OpenIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Group 26">
        <path
          id="Rectangle 11"
          d="M19 37.3848C13.9232 32.308 13.9232 24.0768 19 19C24.0768 24.0768 24.0768 32.308 19 37.3848Z"
          fill="white"
        />
        <path
          id="Rectangle 12"
          d="M37.3848 19C32.308 24.0768 24.0768 24.0768 19 19C24.0768 13.9232 32.308 13.9232 37.3848 19Z"
          fill="white"
        />
        <path
          id="Rectangle 13"
          d="M19 19C13.9232 24.0768 5.69205 24.0768 0.615231 19C5.69205 13.9232 13.9232 13.9232 19 19Z"
          fill="white"
        />
        <path
          id="Rectangle 14"
          d="M19 0.615231C24.0768 5.69205 24.0768 13.9232 19 19C13.9232 13.9232 13.9232 5.69205 19 0.615231Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

const MemoOpenIcon = memo(OpenIcon);
export default MemoOpenIcon;
