import { styled } from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
`;

export const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  svg {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3px;
`;

export const Icon = styled.div`
  img {
    width: 35px;
    height: 35px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;
