import { styled } from "styled-components";

export const Wrapper = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    #fff 26.6%,
    rgba(217, 217, 217, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 50px;
  padding-top: 30px;
  max-width: 900px;

  @media (max-width: 650px) {
    padding: 35px 20px;
    padding-top: 30px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Separator = styled.div`
  width: 130px;
  padding: 2px;
  border-radius: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #53535379 26.6%,
    rgba(217, 217, 217, 0) 100%
  );

  @media (max-width: 500px) {
    width: 90px;
    padding: 1px;
  }
`;
