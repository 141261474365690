import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { AnimatePresence } from "framer-motion";

import App from "./App";
import "./i18n";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="loader-wrapper">
          <div className="lds-hourglass" />
        </div>
      }
    >
      <AnimatePresence>
        <App />
      </AnimatePresence>
    </Suspense>
  </React.StrictMode>
);
