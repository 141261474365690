import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ArrowIcon } from "../../assets/icons";
import mqLogo from "../../assets/images/mqicon-header.png";

import * as S from "./styled";

const Header: React.FC = (): React.ReactElement => {
  const [t, i18n] = useTranslation();
  const [isSelectActive, setIsSelectActive] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("eng");

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setIsSelectActive(false);
  };

  const click = () => {
    window.scrollTo(0,document.body.scrollHeight);
  }

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <S.HeaderWrapper>
      <S.MQInfo>
        <S.MQLogo>
          <img src={mqLogo} alt="mq-logo" />
        </S.MQLogo>
        <S.MQTitle>MQ-LOYALTY</S.MQTitle>
      </S.MQInfo>
      <S.Navbar>
        <S.Item>{t("Header.NavbarItem1")}</S.Item>
        <S.Item onClick = {() => openInNewTab("https://medium.com/@mq.loyalty")}>{t("Header.NavbarItem2")}</S.Item>
        <S.Item onClick ={click}>{t("Header.NavbarItem3")}</S.Item>
        <S.Select
          onClick={() => {
            setIsSelectActive(!isSelectActive);
          }}
        >
          <S.ItemSelect>
            <div>{selectedLanguage}</div>
            <S.ArrowWrapper rotate={isSelectActive ? "180deg" : "0deg"}>
              <ArrowIcon width={"10px"} height={"10px"} />
            </S.ArrowWrapper>
          </S.ItemSelect>
          {isSelectActive && (
            <S.SelectBody>
              <S.LanguageOption
                onClick={() => handleLanguageChange("eng")}
                selected={selectedLanguage === "eng"}
              >
                Eng
              </S.LanguageOption>
              <S.LanguageOption
                onClick={() => handleLanguageChange("ukr")}
                selected={selectedLanguage === "ukr"}
              >
                Ukr
              </S.LanguageOption>
            </S.SelectBody>
          )}
        </S.Select>
      </S.Navbar>
    </S.HeaderWrapper>
  );
};

export { Header };
