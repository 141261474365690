import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";

import * as S from "./styled";
import {fillForm} from "../../helpers/sendForm";

const validationSchema = yup.object().shape({
  name: yup.string().required("Field is required"),
  email: yup.string().email("Invalid email").required("Field is required"),
  company: yup.string().required("Field is required"),
  selectOption: yup.string().required("Select an option"),
  info: yup.string(),
});

interface IValues {
  name: string;
  email: string;
  company: string;
  selectOption: string;
  info: string;
}

const ContactForm: React.FC = (): React.ReactElement => {
  const [t] = useTranslation();

  let initialValues = {
    name: "",
    email: "",
    company: "",
    selectOption: "",
    info: "",
  };

  const handleSubmit = (values: IValues) => {
    fillForm(values.name, values.email, values.company, values.selectOption, values.info).then(
      (el) => {
        window.location.reload();
        initialValues = {
          name: "",
          email: "",
          company: "",
          selectOption: "",
          info: "",
        };
      }
    ).catch((el) => {
    });
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t("ContactForm.Title")}</S.Title>
        <S.Subtitle>{t("ContactForm.Subtitle")}</S.Subtitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <S.Form>
              <S.Field>
                <S.Label htmlFor="name">{t("ContactForm.Label1")}</S.Label>
                <S.Input
                  type="text"
                  id="name"
                  name="name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                  placeholder={t("ContactForm.Placeholder1")}
                />
                {formikProps.touched.name && formikProps.errors.name && (
                  <S.ErrorMessage>
                    {formikProps.errors.name as React.ReactNode}
                  </S.ErrorMessage>
                )}
              </S.Field>
              <S.Field>
                <S.Label htmlFor="email">{t("ContactForm.Label2")}</S.Label>
                <S.Input
                  type="email"
                  id="email"
                  name="email"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.email}
                  placeholder={t("ContactForm.Placeholder2")}
                />
                {formikProps.touched.email && formikProps.errors.email && (
                  <S.ErrorMessage>
                    {formikProps.errors.email as React.ReactNode}
                  </S.ErrorMessage>
                )}
              </S.Field>
              <S.Field>
                <S.Label htmlFor="company">{t("ContactForm.Label3")}</S.Label>
                <S.Input
                  type="text"
                  id="company"
                  name="company"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.company}
                  placeholder={t("ContactForm.Placeholder3")}
                />
                {formikProps.touched.company && formikProps.errors.company && (
                  <S.ErrorMessage>
                    {formikProps.errors.company as React.ReactNode}
                  </S.ErrorMessage>
                )}
              </S.Field>
              <S.Field>
                <S.Label htmlFor="selectOption">
                  {t("ContactForm.Label4")}
                </S.Label>
                <S.Select
                  id="selectOption"
                  name="selectOption"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.selectOption}
                >
                  <option value="" disabled hidden>
                    {t("ContactForm.Option1")}
                  </option>
                  <option value="yes">{t("ContactForm.Option2")}</option>
                  <option value="no">{t("ContactForm.Option3")}</option>
                </S.Select>
                {formikProps.touched.selectOption &&
                  formikProps.errors.selectOption && (
                    <S.ErrorMessage>
                      {formikProps.errors.selectOption as React.ReactNode}
                    </S.ErrorMessage>
                  )}
              </S.Field>
              <S.Field>
                <S.Label htmlFor="info">{t("ContactForm.Label5")}</S.Label>
                <S.Input
                  type="text"
                  id="info"
                  name="info"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.info}
                  placeholder={t("ContactForm.Placeholder5")}
                />
                {formikProps.touched.info && formikProps.errors.info && (
                  <S.ErrorMessage>
                    {formikProps.errors.info as React.ReactNode}
                  </S.ErrorMessage>
                )}
              </S.Field>
              <S.SendButton
                onClick={() => {
                  handleSubmit(formikProps.values);
                }}
                type="submit"
              >
                {t("ContactForm.TextButton")}
              </S.SendButton>
            </S.Form>
          )}
        </Formik>
      </S.Content>
    </S.Wrapper>
  );
};

export { ContactForm };
