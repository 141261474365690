import React, { SVGProps, FC, memo } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const StarIcon: FC<IProps> = ({ ...rest }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M17.7722 9.5C17.7722 8.5335 16.9887 7.75 16.0222 7.75C15.0557 7.75 14.2722 8.5335 14.2722 9.5V12.9817L11.2459 11.2345C10.4089 10.7512 9.33859 11.038 8.85534 11.875C8.37209 12.712 8.65888 13.7823 9.49589 14.2656L12.5 16L9.49578 17.7345C8.65876 18.2177 8.37198 19.288 8.85523 20.125C9.33848 20.962 10.4088 21.2488 11.2458 20.7656L14.2722 19.0182V22.5C14.2722 23.4665 15.0557 24.25 16.0222 24.25C16.9887 24.25 17.7722 23.4665 17.7722 22.5V19.0439L20.7542 20.7656C21.5912 21.2488 22.6615 20.962 23.1448 20.125C23.628 19.288 23.3412 18.2177 22.5042 17.7345L19.5 16L22.5041 14.2656C23.3411 13.7823 23.6279 12.712 23.1447 11.875C22.6614 11.038 21.5911 10.7512 20.7541 11.2345L17.7722 12.9561V9.5Z" />
    </svg>
  );
};

const MemoStarIcon = memo(StarIcon);
export default MemoStarIcon;
