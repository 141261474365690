import { GoogleSpreadsheet } from 'google-spreadsheet';
import { JWT } from 'google-auth-library';
import creds from './mq-loyalty-keys.json';
export const fillForm = async (yourName, companyName, companyEmail, existingLoyaltyProgram, additionalInformation) => {

  const serviceAccountAuth = new JWT({
    // env var values here are copied from service account credentials generated by google
    // see "Authentication" section in docs for more info
    email: creds.client_email,
    key: creds.private_key,
    scopes: [
      'https://www.googleapis.com/auth/spreadsheets',
    ],
  });

  const doc = new GoogleSpreadsheet(
    '1i1Rt0-Hr0bseUnfYn45ByQaTOi_qK0TiVAeEM3c99dY',
    serviceAccountAuth
  );

  await doc.loadInfo();

  const sheet = doc.sheetsByTitle['Filled forms'];
  const rows = await sheet.getRows();

  const rawValues = rows.map((row) => row._rawData);

  const currentWeek = Number(rawValues[rawValues.length - 1][0]) + 1;
  const newRow = [
    currentWeek,
    yourName,
    companyName,
    companyEmail,
    existingLoyaltyProgram,
    additionalInformation
  ];


  await sheet.addRow(newRow)

}
